export const OPTION_POST_TYPE_KNOWLEDGE = [
  {
    id: 1,
    name: 'Người lớn',
    value: 'baceb38c-02ed-435c-88b8-c956b81c3deb'
  },
  {
    id: 2,
    name: 'Trẻ em',
    value: 'fe3b53b1-a448-41ac-b933-7e159f9f1643'
  }
]

export const OPTION_FILTER_KNOWLEDGE = [
  {
    id: 1,
    name: 'Người lớn',
    value: 'adult'
  },
  {
    id: 2,
    name: 'Trẻ em',
    value: 'children'
  }
]

export const OPTION_POST_TYPE = [
  {
    id: 1,
    name: 'Nghiên cứu & phát triển',
    value: 'research-development'
  },
  {
    id: 2,
    name: 'Tin tức',
    value: 'news'
  },
  {
    id: 3,
    name: 'Single',
    value: 'single'
  }
]

export const OBJECT_POST_TYPE = {
  news: 'Tin tức',
  'research-development': 'Nghiên cứu & phát triển',
  single: 'Single'
}

export const OPTION_CONTACT = [
  {
    id: 1,
    name: 'Đã liên hệ',
    value: 'checked'
  },
  {
    id: 2,
    name: 'Chưa liên hệ',
    value: 'new'
  }
]

export const OBJECT_CONTACT = {
  new: 'Chưa liên hệ',
  checked: 'Đã liên hệ'
}

export const OBJECT_SLUG_CATEGORY = {
  adult: 'nguoi-lon',
  children: 'tre-em'
}

export const OBJECT_SLUG_POST_TYPE = {
  news: 'tin-tuc',
  'research-development': 'nghien-cuu',
  single: 'tin-tuc'
}

export const OPTION_RATING = [
  {
    id: 1,
    name: '★',
    value: 1
  },
  {
    id: 2,
    name: '★★',
    value: 2
  },
  {
    id: 3,
    name: '★★★',
    value: 3
  },
  {
    id: 4,
    name: '★★★★',
    value: 4
  },
  {
    id: 5,
    name: '★★★★★',
    value: 5
  }
]
